<template>
  <div>
    <h1 class="text-size-h2 text-bold mb-m">{{ $metaInfo.title }}</h1>
    <div class="typography mb-l">
      <p>
        Вы&nbsp;можете получить привилегии на&nbsp;основе
        <a
          class="link"
          href="https://talent.kruzhok.org/achievements"
          target="_blank"
          rel="noopener noreferrer"
          >ваших достижений</a
        >, учтенных на&nbsp;платформе &laquo;Талант&raquo;. Привилегии бывают
        разные: некоторые добавляют баллы к&nbsp;результату отборочных этапов,
        другие позволяют пропустить часть этапов и&nbsp;даже попасть напрямую
        в&nbsp;финал. Профили учитывают разные достижения и&nbsp;дают разные
        привилегии.
      </p>

      <p>
        Здесь собраны ваши привилегии. Чтобы ими воспользоваться, необходимо
        их&nbsp;активировать&nbsp;&mdash; это не&nbsp;происходит автоматически.
      </p>

      <p>
        Привилегии можно использовать в&nbsp;ограниченный срок&nbsp;&mdash;
        дата, до&nbsp;которой необходимо активировать каждую привилегию, указана
        в&nbsp;ее&nbsp;описании. После этого активировать привилегию невозможно.
      </p>

      <p>
        Чтобы получить дополнительные баллы с&nbsp;помощью привилегии,
        необходимо набрать больше 0&nbsp;баллов на&nbsp;отборочных этапах
        (то&nbsp;есть решить хотя&nbsp;бы одну задачу самостоятельно).
      </p>

      <p>
        При активации привилегий внимательно следите, на&nbsp;каком профиле
        вы&nbsp;их&nbsp;используете. Изменить этот выбор нельзя. Также
        вы&nbsp;не&nbsp;сможете отказаться от&nbsp;профиля и&nbsp;сменить его
        на&nbsp;другой, если вы&nbsp;уже применили для него привилегию.
      </p>
    </div>
    <div
      v-if="list && list.length"
      class="cards-list privilege-list">
      <div
        v-for="item in list"
        :key="item.id"
        class="cards-list__item">
        <div
          class="privilege"
          :class="[
            `privilege--${item.type}`,
            { 'privilege--wasted': item.profile_id },
          ]">
          <div class="privilege__icon">
            <BaseIcon
              v-if="!item.score"
              glyph="star" />
            <span
              v-else
              class="privilege__icon-text"
              >+{{ item.score }}</span
            >
          </div>
          <div class="privilege__data">
            <div class="privilege__title">{{ item.title }}</div>
            <div class="privilege__text color-meta">{{ item.description }}</div>
            <div
              v-if="item.expired_at && !item.profile_id"
              class="privilege__text color-primary">
              Можно воспользоваться до: {{ item.formatedExpired }}
            </div>
            <div class="mt-m">
              <BaseButton
                v-if="!item.profile_id && !item.isExpired"
                @click.prevent="handleOpenPrivilege(item.id)"
                >Использовать привилегию</BaseButton
              >

              <div
                v-else-if="item.profile_id"
                class="privilege__wasted">
                Привилегия учтена для профиля &laquo;{{
                  profiles[item.profile_id] && profiles[item.profile_id].title
                }}&raquo;
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p>
        У&nbsp;вас пока нет привилегий. Набирайте темп! Желаем вам получить
        их&nbsp;в&nbsp;следующем году!
      </p>
    </div>
  </div>
</template>

<script>
import ExtraScoresModal from "@/components/user/modals/ExtraScoresModal.vue";
import { DATE_FROMAT_WITH_UTC, MODAL_DYNAMIC_DEFAULTS } from "@/constants";
import dayjs from "@/plugins/dayjs";
export default {
  name: "Privileges",
  metaInfo() {
    return {
      title: "Мои привилегии",
    };
  },
  computed: {
    profiles() {
      return this.$store.state.profile.profiles;
    },
    list() {
      const list = this.$store.getters["participant/privileges"];
      return list.map((n) => {
        let type = "score";
        if (n.pass_through) {
          type = "pass";
        } else if (n.final_pass) {
          type = "final_pass";
        }
        const result = {
          ...n,
          type,
          isExpired: false,
        };
        if (n.expired_at) {
          result.isExpired = dayjs().isAfter(n.expired_at);
          result.formatedExpired = dayjs(n.expired_at).format(
            DATE_FROMAT_WITH_UTC
          );
        }

        return result;
      });
    },
  },
  methods: {
    handleOpenPrivilege(id) {
      this.$modal.show(
        ExtraScoresModal,
        {
          privilege: id,
        },
        {
          ...MODAL_DYNAMIC_DEFAULTS,
          width: 800,
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
.privilege {
  border-radius: @radius-m;
  background-color: #fff;
  box-shadow: @shadow-default;
  min-height: 100%;
  border-radius: @radius-xs;
  padding: 24px;
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 20px;

  &-list {
    --grid-gutter-width: 10px;
  }

  &__icon {
    flex-grow: 0;
    flex-shrink: 0;
    width: 2em;
    height: 2em;
    font-size: 32px;
    text-align: center;
    background-color: @bg-light;
    color: @primary-color;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border-radius: @radius-xs;
    border-radius: 50%;
    border: 3px solid #fff;
    transform: translateY(-50%);
    box-shadow: 0px 10px 10px @tropical-blue;
    margin-right: 20px;
    &-text {
      font-size: 0.7em;
    }
  }

  &--pass &__icon {
    background-color: @primary-color;
    color: #fff;
  }

  &--final_pass &__icon {
    background-color: @success;
    color: #fff;
  }

  &__title {
    .fz(18);
    margin-bottom: 0.35em;
    font-weight: bold;
  }
  &__text {
    .fz(14);
  }

  &__wasted {
    padding: 20px;
    background-color: @bg-light;
    font-weight: bold;
  }

  .media-min-sm({
    display: block;
  });
}
</style>
